import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import JSON5 from 'json5';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Datasheet, DatasheetDetailResponse, DatasheetInformation } from '@priva/next-model';

import { NextConfiguration } from 'app/configuration';
import { getHttpHeadersAction } from 'app/core/http';
import { AppActions } from 'app/state';

import { DatasheetApiActions, DatasheetState } from '.';

@Injectable({
    providedIn: 'root',
})
export class DatasheetApiEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpClient = inject(HttpClient);
    private readonly nextConfiguration = inject(NextConfiguration);
    private readonly store$ = inject<Store<{ datasheet: DatasheetState }>>(Store);

    public getAllDatasheets$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DatasheetApiActions.getAllDatasheets),
            withLatestFrom(this.store$),
            filter(([_action, state]) => !state.datasheet.sorted.length),
            mergeMap(([action, _state]) => {
                const url = this.nextConfiguration.datasheetsApiPaths.searchDatasheets();

                return this.httpClient
                    .get<Datasheet[]>(url, { headers: getHttpHeadersAction(action.type) })
                    .pipe(
                        map((datasheets: Datasheet[]) =>
                            DatasheetApiActions.getAllDatasheetsSuccess({ datasheets }),
                        ),
                        catchError(
                            /* istanbul ignore next ec, just dispatch second action */
                            (error) => of(AppActions.apiError({ error, action })),
                        ),
                    );
            }),
        );
    });

    public getProtocolDatasheets$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DatasheetApiActions.getProtocolDatasheets),
            withLatestFrom(this.store$),
            filter(([_action, state]) => !state.datasheet.sorted.length),
            mergeMap(([action, _state]) => {
                const url = this.nextConfiguration.datasheetsApiPaths.searchDatasheets(action.protocol);

                return this.httpClient.get<Datasheet[]>(url).pipe(
                    map((datasheets: Datasheet[]) =>
                        DatasheetApiActions.getAllDatasheetsSuccess({ datasheets }),
                    ),
                    catchError(
                        /* istanbul ignore next ec, just dispatch second action */
                        (error) => of(AppActions.apiError({ error, action })),
                    ),
                );
            }),
        );
    });

    public getDatasheetDetail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DatasheetApiActions.getDatasheetDetail),
            mergeMap((action) => {
                let url: string;
                switch (action.protocolType) {
                    case 'Modbus': {
                        url = this.nextConfiguration.datasheetsApiPaths.datasheetModbus(action.datasheetId);
                        break;
                    }

                    case 'ConventionalIo': {
                        url = this.nextConfiguration.datasheetsApiPaths.datasheetConventionalIO(
                            action.datasheetId,
                        );
                        break;
                    }

                    case 'Dali': {
                        url = this.nextConfiguration.datasheetsApiPaths.datasheetDali(action.datasheetId);
                        break;
                    }
                }
                return this.httpClient.get<DatasheetDetailResponse>(url).pipe(
                    map((datasheet) => {
                        return DatasheetApiActions.getDatasheetDetailSuccess({
                            datasheet: {
                                ...datasheet,
                                datasheetInformation: this.wrapIntoDatasheetInformation(
                                    datasheet.datasheetInformation,
                                ),
                                protocolType: action.protocolType,
                            },
                        });
                    }),
                    catchError(
                        /* istanbul ignore next ec, just dispatch second action */
                        (error) => of(AppActions.apiError({ error, action })),
                    ),
                );
            }),
        );
    });

    public createDatasheet$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DatasheetApiActions.createDatasheet),
            mergeMap((action) => {
                const url = this.nextConfiguration.datasheetsApiPaths.datasheets();

                return this.httpClient.post<Datasheet>(url, action.datasheet).pipe(
                    map((datasheet: Datasheet) => DatasheetApiActions.createDatasheetSuccess({ datasheet })),
                    catchError(
                        /* istanbul ignore next ec, just dispatch second action */
                        (error) => of(AppActions.apiError({ error, action })),
                    ),
                );
            }),
        );
    });

    public updateDatasheet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DatasheetApiActions.updateDatasheet),
            mergeMap((action) => {
                const url = this.nextConfiguration.datasheetsApiPaths.datasheet(action.datasheet.id);
                return this.httpClient.patch<Datasheet>(url, action.datasheet).pipe(
                    map((datasheet: Datasheet) => DatasheetApiActions.updateDatasheetSuccess({ datasheet })),
                    catchError(
                        /* istanbul ignore next tvr, just dispatch second action */
                        (error) => of(AppActions.apiError({ error, action })),
                    ),
                );
            }),
        ),
    );

    public deleteDatasheet$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DatasheetApiActions.deleteDatasheet),
            mergeMap((action) => {
                const url = this.nextConfiguration.datasheetsApiPaths.datasheet(action.datasheetId);

                return this.httpClient.delete(url).pipe(
                    map(() =>
                        DatasheetApiActions.deleteDatasheetSuccess({ datasheetId: action.datasheetId }),
                    ),
                    catchError(
                        /* istanbul ignore next ec, just dispatch second action */
                        (error) => of(AppActions.apiError({ error, action })),
                    ),
                );
            }),
        );
    });

    private readonly wrapIntoDatasheetInformation = (
        stringifiedDatasheetInformation: string,
    ): DatasheetInformation => {
        return JSON5.parse(stringifiedDatasheetInformation);
    };
}
