import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Action, Store } from '@ngrx/store';
import { filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import { findRouterStateParam } from 'app/core/utils';
import { AppActions } from 'app/state';
import { ConfigurationApiActions } from 'app/template/system-component/configuration-view/state';

import { DatasheetActions, DatasheetApiActions, DatasheetState } from '.';

interface StateScope {
    datasheet: DatasheetState;
}

@Injectable({
    providedIn: 'root',
})
export class DatasheetEffects {
    private readonly actions$ = inject(Actions);
    private readonly store$ = inject<Store<StateScope>>(Store);
    private readonly router = inject(Router);

    public routerNavigated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter((action: RouterNavigatedAction) => !action.payload.routerState.url.startsWith('/error')),
            filter(
                (action: RouterNavigatedAction) =>
                    action.payload.routerState.url.indexOf('/templates') !== -1,
            ),
            withLatestFrom(this.store$),
            mergeMap(([action, state]) => {
                const actions: Action[] = [];
                const datasheetId = findRouterStateParam(
                    'datasheetId',
                    action.payload.routerState.root.children,
                );

                const datasheetExists = state.datasheet.datasheets.find((d) => d.id === datasheetId);
                if (datasheetId && !datasheetExists) {
                    actions.push(DatasheetActions.markForDetailsFetch({ datasheetId }));
                }

                return actions;
            }),
        ),
    );

    public navigateToDatasheet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DatasheetActions.navigateToDatasheet),
            tap((action) => {
                this.router.navigate([
                    '/solutions',
                    action.solutionId,
                    'templates',
                    'datasheets',
                    action.datasheet.id,
                ]);
            }),
            map((action) =>
                DatasheetActions.addActiveDatasheetDetailsId({
                    datasheetId: action.datasheet.id,
                    protocolType: action.datasheet.protocolType,
                }),
            ),
        ),
    );

    public getAllDatasheets$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.initializeAppSuccess),
            map(() => DatasheetApiActions.getAllDatasheets()),
        ),
    );

    public resolveMarkedForDetailsFetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DatasheetApiActions.getAllDatasheetsSuccess),
            withLatestFrom(this.store$),
            filter(([, state]) => !!state.datasheet.datasheetDetailIdToFetch),
            map(([action, state]) => {
                const datasheet = action.datasheets.find(
                    (d) => d.id === state.datasheet.datasheetDetailIdToFetch,
                );
                return DatasheetActions.getDatasheetDetailIfNeeded({
                    datasheetId: datasheet.id,
                    protocolType: datasheet.protocolType,
                });
            }),
        ),
    );

    public getDatasheetDetailIfNeeded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DatasheetActions.getDatasheetDetailIfNeeded),
            withLatestFrom(this.store$),
            filter(([action, state]) => !state.datasheet.datasheets.some((d) => d.id === action.datasheetId)),
            map(([action]) =>
                DatasheetApiActions.getDatasheetDetail({
                    datasheetId: action.datasheetId,
                    protocolType: action.protocolType,
                }),
            ),
        ),
    );

    public onLoadSetActiveDatasheetDetailsId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DatasheetActions.addActiveDatasheetDetailsId),
            map((action) => DatasheetActions.getDatasheetDetailIfNeeded(action)),
        ),
    );

    public clearSelectedDatasheet = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationApiActions.getDatasheetBindings),
            map(() => DatasheetActions.clearActiveDatasheetDetailsIds()),
        ),
    );

    public setActiveDatasheetIdsDatasheetBindings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationApiActions.getDatasheetBindingsSuccess),
            filter((action) => action.datasheetBindings.length > 0),
            map((action) => {
                const activeDatasheetIds = action.datasheetBindings.map((db) => db.datasheetId);
                return DatasheetActions.setActiveDatasheetDetailsIds({ datasheetIds: activeDatasheetIds });
            }),
        ),
    );

    public getDatasheetDetailsIfNeeded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationApiActions.getDatasheetBindingsSuccess),
            mergeMap((action) => {
                let datasheetIds = action.datasheetBindings.map(
                    (datasheetBinding) => datasheetBinding.datasheetId,
                );
                datasheetIds = [...new Set(datasheetIds)];
                return datasheetIds.map((datasheetId) => {
                    return DatasheetActions.getDatasheetDetailIfNeeded({
                        datasheetId,
                        protocolType: action.datasheetBindings.find((db) => db.datasheetId === datasheetId)
                            .protocolType,
                    });
                });
            }),
        ),
    );
}
